'use client';

import useMetricsCubeQuery from 'app/hooks/useMetricsCubeQuery';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { useLocale, useTranslations } from 'next-intl';
import { useMemo } from 'react';

import {
  MetricsCubeBasicTimeFrame,
  MetricsCubeMetric,
} from '@carbonfact/shared/src/types/platform/metrics-cube';
import YtdVolumeWidget from './YtdVolumeWidget';

dayjs.extend(utc);

const currentYear = dayjs().utc().year();
const lastYear = dayjs().utc().subtract(1, 'year').year();
const yearBeforeLast = dayjs().utc().subtract(2, 'year').year();

export default function YtdTrends() {
  const t = useTranslations();
  const locale = useLocale();

  dayjs.locale(locale);

  const { data: ytmData, isLoading: isLoadingYtmData } = useMetricsCubeQuery({
    metric: MetricsCubeMetric.Footprint,
    timeFrame: MetricsCubeBasicTimeFrame.YearToMonth,
  });

  const lastYtmData = ytmData?.find(
    (d) => d.period.startDate.getUTCFullYear() === currentYear,
  );
  const previousYtmData = ytmData?.find(
    (d) => d.period.startDate.getUTCFullYear() === lastYear,
  );

  const canShowYtmData = !!lastYtmData;

  const { data: yearData, isLoading: isLoadingYearData } = useMetricsCubeQuery(
    // Only fetch when we don't have YTM data
    !isLoadingYtmData && !canShowYtmData
      ? {
          metric: MetricsCubeMetric.Footprint,
          timeFrame: MetricsCubeBasicTimeFrame.Year,
        }
      : null,
  );

  const lastYearData = yearData?.find(
    (d) => d.period.startDate.getUTCFullYear() === lastYear,
  );
  const previousYearData = yearData?.find(
    (d) => d.period.startDate.getUTCFullYear() === yearBeforeLast,
  );
  const canShowYearData = !!lastYearData;

  const dateIndicatorString = useMemo(() => {
    if (!canShowYtmData && !canShowYearData) {
      // No data available
      return t('YtdTrends.dateRange.noData', { year: lastYear });
    }

    if (canShowYtmData) {
      if (previousYtmData) {
        return t('YtdTrends.dateRange.ytmCompare', {
          lastStartMonth: dayjs(lastYtmData.period.startDate)
            .utc()
            .format('MMMM'),
          lastEndMonth: dayjs(lastYtmData.period.endDate).utc().format('MMM'),
          lastYear: dayjs(lastYtmData.period.endDate).utc().format('YYYY'),
          previousStartMonth: dayjs(previousYtmData.period.startDate)
            .utc()
            .format('MMM'),
          previousEndMonth: dayjs(previousYtmData.period.endDate)
            .utc()
            .format('MMM'),
          previousYear: dayjs(previousYtmData.period.endDate)
            .utc()
            .format('YYYY'),
        });
      }

      return t('YtdTrends.dateRange.ytm', {
        lastStartMonth: dayjs(lastYtmData.period.startDate)
          .utc()
          .format('MMMM'),
        lastEndMonth: dayjs(lastYtmData.period.endDate).utc().format('MMM'),
        lastYear: dayjs(lastYtmData.period.endDate).utc().format('YYYY'),
      });
    }

    if (canShowYearData) {
      if (previousYearData) {
        return t('YtdTrends.dateRange.yearCompare', {
          year: dayjs(lastYearData.period.endDate).utc().format('YYYY'),
          previousYear: dayjs(previousYearData.period.endDate)
            .utc()
            .format('YYYY'),
        });
      }

      return t('YtdTrends.dateRange.year', {
        year: dayjs(lastYearData.period.endDate).utc().format('YYYY'),
      });
    }
  }, [
    t,
    canShowYtmData,
    canShowYearData,
    lastYtmData,
    previousYtmData,
    lastYearData,
    previousYearData,
  ]);

  const isLoading = isLoadingYtmData || isLoadingYearData;

  const shownPeriod = canShowYtmData
    ? lastYtmData?.period
    : lastYearData?.period;
  const previousPeriod = canShowYtmData
    ? previousYtmData?.period
    : previousYearData?.period;

  return (
    <section className="w-full">
      <h2 className="text-carbon-800 text-base font-medium">
        {t('YtdTrends.keyFigures')}{' '}
      </h2>
      <h3 className="text-carbon-500 text-xs font-medium mb-3">
        {dateIndicatorString}
      </h3>

      <div className="w-full flex flex-row flex-wrap gap-2 [align-items:stretch] [justify-content:stretch]">
        <YtdVolumeWidget
          metric={MetricsCubeMetric.Footprint}
          isLoading={isLoading}
          period={shownPeriod}
          compareToPeriod={previousPeriod}
        />
        <YtdVolumeWidget
          metric={MetricsCubeMetric.Footprint}
          overMetric={MetricsCubeMetric.UnitsMeasured}
          isLoading={isLoading}
          period={shownPeriod}
          compareToPeriod={previousPeriod}
        />
        <YtdVolumeWidget
          metric={MetricsCubeMetric.UnitsMeasured}
          isLoading={isLoading}
          period={shownPeriod}
          compareToPeriod={previousPeriod}
        />
      </div>
    </section>
  );
}
