import formatNumber from '@carbonfact/shared/src/utils/formatNumber';
import * as Plot from '@observablehq/plot';

import type { NetZeroChartProps } from '../..';

export const getYAxis = (axisYSettings: NetZeroChartProps['axisYSettings']) =>
  Plot.axisY({
    tickSize: 0,
    tickFormat: (number: number) =>
      axisYSettings?.format
        ? axisYSettings.format(number)
        : formatNumber(number),
    tickPadding: 13,
    tickSpacing: 30,
    fill: '#989B9D',
    label: axisYSettings?.label ?? 'CO2e (t)',
    labelArrow: 'none',
    labelAnchor: 'top',
    fontVariant: 'normal',
    marginTop: 30,
  });
