import * as Plot from '@observablehq/plot';

import { formatCount } from '@carbonfact/shared/src/utils/formatNumber';
import type { D3NetZeroObject } from '../..';

export const getKnownDataPlot = ({ data }: { data: D3NetZeroObject[] }) => {
  const knownData = data.filter((d) => !d.future);

  // This hack is used to conditionally render tooltips
  const futureDataWithHover = data.filter((d) => d.future && d.futureHoverText);
  const futureDataWithoutHover = data.filter(
    (d) => d.future && !d.futureHoverText,
  );

  const futureDataStructure = {
    x: 'year',
    y: 'value',
    fill: '#FFFFFF',
    stroke: 'type',
    strokeWidth: 1,
    r: 3,
    opacity: 0.7,
  };
  return [
    Plot.lineY(knownData, {
      x: 'year',
      y: 'value',
      stroke: 'type',
      strokeWidth: 2,
    }),
    Plot.dot(knownData, {
      x: 'year',
      y: 'value',
      fill: '#FFFFFF',
      stroke: 'type',
      strokeWidth: 2,
      tip: true,
      title: (d) => formatCount(d.value),
      r: 3,
    }),

    // Plot future data with hoverText (with tooltips)
    Plot.dot(futureDataWithHover, {
      ...futureDataStructure,
      tip: true,
      title: (d) => d.futureHoverText,
    }),
    // Plot future data without hoverText (without tooltips)
    Plot.dot(futureDataWithoutHover, {
      ...futureDataStructure,
    }),
  ];
};
