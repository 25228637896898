'use client';

import { Button } from '@carbonfact/ui-components/src/Button';
import useEndpoint from 'app/hooks/useEndpoint';
import { useRouter } from 'app/navigation';
import { useTranslations } from 'next-intl';

export default function ChangelogButton() {
  const { data: latestRelease } = useEndpoint('/release/latest');
  const t = useTranslations();

  const router = useRouter();

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <span className="text-carbon-500 text-sm font-medium">
          {latestRelease ? (
            <>
              {t('VersionHistory.version')} {`v${latestRelease.version}`}
            </>
          ) : (
            <>{t('VersionHistory.fetchingVersionHistory')}</>
          )}
        </span>
        <Button.Default
          onClick={() => void router.push('/home/changelog')}
          variant="secondary"
        >
          {t('VersionHistory.changelog')}
        </Button.Default>
      </div>
    </>
  );
}
