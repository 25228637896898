'use client';
import {
  MetricsCubeBasicTimeFrame,
  MetricsCubeMetric,
  type MetricsCubeQueryOptions,
  type MetricsCubeQueryResponseData,
} from '@carbonfact/shared/src/types/platform/metrics-cube';
import { NetZeroChartType } from 'app/components/Chart/variants/NetZero';
import useEndpoint from 'app/hooks/useEndpoint';

import useMetricsCubeQuery from './useMetricsCubeQuery';

export interface NetZeroTrajectoryData {
  rawData: MetricsCubeQueryResponseData[];
  isLoading: boolean;
  chartType: NetZeroChartType;
  formattedDataPoints: { year: number; value: number }[];
  baselineYear: number;
  baselineYearValue: number;
  modelingBaselineYear: number;
  modelingBaselineYearValue: number;
}

export default function useNetZeroTrajectoryData(
  chartType: NetZeroChartType = NetZeroChartType.TotalFootprint,
): NetZeroTrajectoryData {
  const { data: accountMetadata, isLoading: isLoadingMetadata } = useEndpoint(
    '/metadata',
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { data: cubeData, isLoading: isLoadingCubeData } = useMetricsCubeQuery({
    ...getMetricsCubeOptionsForChartType(chartType),
    timeFrame: MetricsCubeBasicTimeFrame.Year,
  });

  const isLoading = isLoadingMetadata || isLoadingCubeData;

  if (!accountMetadata || !cubeData || cubeData.length === 0) {
    return {
      rawData: [],
      isLoading,
      chartType,
      formattedDataPoints: [],
      baselineYear: 0,
      baselineYearValue: 0,
      modelingBaselineYear: 0,
      modelingBaselineYearValue: 0,
    };
  }

  const sortedCubeData = cubeData.sort((a, b) => {
    return b.period.startDate.getTime() - a.period.startDate.getTime();
  });

  const firstYear = sortedCubeData.at(-1)?.period.startDate.getUTCFullYear();
  const firstYearValue = sortedCubeData.at(-1)?.metricValue;

  // Read baseline year from account settings
  const baselineYear = accountMetadata.baselineYear;
  const baselineYearValue = cubeData.find(
    (d) => d.period.startDate.getUTCFullYear() === baselineYear,
  )?.metricValue;

  let modelingBaselineYear = accountMetadata.modelingBaselineYear;
  let modelingBaselineYearValue = cubeData.find(
    (d) => d.period.startDate.getUTCFullYear() === modelingBaselineYear,
  )?.metricValue;

  const formattedDataPoints = cubeData
    .map((d) => ({
      year: d.period.startDate.getUTCFullYear(),
      value: d.metricValue,
    }))
    // Remove all data points before baseline year if it is set
    .filter((point) => (baselineYear ? point.year >= baselineYear : true));

  const latestYear = sortedCubeData[0].period.startDate.getUTCFullYear();
  const latestYearValue = sortedCubeData[0].metricValue;

  if (!modelingBaselineYear || !modelingBaselineYearValue) {
    const hasMoreThanOneYearOfData = cubeData.length > 1;

    if (hasMoreThanOneYearOfData) {
      // We don't want current year as it's usually incomplete
      const isLatestYearCurrentYear =
        latestYear === new Date().getUTCFullYear();

      modelingBaselineYear = isLatestYearCurrentYear
        ? // get one year before
          cubeData[1].period.startDate.getUTCFullYear()
        : latestYear;

      modelingBaselineYearValue = isLatestYearCurrentYear
        ? // get one year before
          cubeData[1].metricValue
        : latestYearValue;
    } else {
      modelingBaselineYear = latestYear;
      modelingBaselineYearValue = latestYearValue;
    }
  }

  let divider = 1;

  if (chartType === NetZeroChartType.TotalFootprint) {
    divider = 1000;
  }

  return {
    rawData: cubeData,
    isLoading,
    chartType,
    formattedDataPoints: formattedDataPoints.map((d) => ({
      year: d.year,
      value: (d.value || 0) / divider,
    })),
    baselineYear: baselineYear || firstYear || 2022,
    baselineYearValue: (baselineYearValue || firstYearValue || 0) / divider,
    modelingBaselineYear: modelingBaselineYear,
    modelingBaselineYearValue: (modelingBaselineYearValue || 0) / divider,
  };
}

function getMetricsCubeOptionsForChartType(
  chartType: NetZeroChartType,
): Pick<MetricsCubeQueryOptions, 'metric' | 'overMetric'> {
  switch (chartType) {
    case NetZeroChartType.TotalFootprint:
      return { metric: MetricsCubeMetric.Footprint };
    case NetZeroChartType.PerUnitFootprint:
      return {
        metric: MetricsCubeMetric.Footprint,
        overMetric: MetricsCubeMetric.UnitsMeasured,
      };
  }
}
