import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/carbonfact/carbonfact/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/carbonfact/carbonfact/platform/app/[locale]/(auth)/home/components/AccountGreet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/carbonfact/carbonfact/platform/app/[locale]/(auth)/home/components/ChangelogButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/carbonfact/carbonfact/platform/app/[locale]/(auth)/home/components/ReleaseMetricsTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/carbonfact/carbonfact/platform/app/[locale]/(auth)/home/components/YtdTrends.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/carbonfact/carbonfact/platform/app/components/NetZeroOverview.tsx");
